import React, { Component } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';
import Countdown from 'react-countdown-now';

const iconBolt = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="payment__form--icon mr-3"><path d="M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.4 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36z"/></svg>);
const iconLink = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="payment__form--icon mr-3"><path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"/></svg>);
const iconCopy = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="white" className="payment__form--icon mr-3"><path d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"/></svg>);
const iconExternalLink = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="white" className="payment__form--icon mr-3"><path d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"/></svg>);

const renderer = ({ total, days, hours, minutes, seconds, milliseconds, completed }) => {
  return (<div className="d-flex justify-content-around">
    <div>{minutes}</div>
    <div>:</div>
    <div>{seconds}</div>
  </div>);
}

class PaymentAcceptor extends Component {

  constructor(props) {
    super(props);
    this.state = {copied: false, choosenTab: "on_chain"}
  }

  onCopy = () => {
    this.setState({
      copied: true
    })
    setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  }

  toggleTab = (event) => {
    this.setState({ choosenTab: event.currentTarget.dataset.tabtype })
  }

  lightningExpired = (time) => {
    window.location.reload(true);
  }

  render () {
    let qrcode;
    let address;
    let chainPaymentAddress = this.props.payment.chain_address;
    let lightningRequestHash = this.props.payment.lightning_request_hash;
    let amountInBtc = (this.props.payment.satoshi_amount/100000000);

    if (this.state.choosenTab == "on_chain") {
      qrcode = ("bitcoin:" + chainPaymentAddress + "?amount=" + amountInBtc);
      address = chainPaymentAddress;
    }
    if (this.state.choosenTab == "lightning") {
      qrcode = ("lightning:" + lightningRequestHash);
      address = lightningRequestHash;
    }

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4>Send payment:</h4>
            <p className="payment__heading--sub-text mb-0">The whole amount should be sent as one transaction.</p>
          </div>
          <div className="ml-4">
            <Countdown date={new Date(this.props.payment.lightning_request_expires_at)} renderer={renderer} onComplete={this.lightningExpired}/>
          </div>
        </div>

        <div className="card mt-4">
          <div className="payment__tab--container container-fluid">
            <div className="row align-items-center justify-content-between">
              <div onClick={this.toggleTab} data-tabtype="on_chain" className={"d-flex align-items-center col-6 payment__tab--item px-4 py-3 " + (this.state.choosenTab == "on_chain" ? "payment__tab--item-active" : "payment__tab--item-inactive")}>
                {iconLink}
                <div>
                  <div className="font-weight-bold">On-chain</div>
                  <div className="text-secondary">Standard Bitcoin Wallet</div>
                </div>
              </div>
              <div onClick={this.toggleTab} data-tabtype="lightning" className={"d-flex align-items-center col-6 payment__tab--item px-4 py-3 " + (this.state.choosenTab == "lightning" ? "payment__tab--item-active" : "payment__tab--item-inactive")}>
                {iconBolt}
                <div>
                  <div className="font-weight-bold">Lightning</div>
                  <div className="text-secondary">Lightning Network Wallet</div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="text-center container-fluid">
              <QRCode value={this.state.choosenTab == "lightning" ? address : qrcode} size={200} />
              <div className="d-flex justify-content-center mt-3">
                <div className="mr-3">
                  <a href={qrcode} className="btn btn-primary qr-container--btn d-flex align-items-center">
                    {iconExternalLink}
                    <span>Open in wallet</span>
                  </a>
                </div>
                <CopyToClipboard onCopy={this.onCopy} text={address}>
                  <button className={"d-flex align-items-center qr-container--btn btn " + (this.state.copied ? 'btn-success' : 'btn-secondary')} type="button">
                    {iconCopy}
                    {this.state.copied ? 'Copied!' : 'Copy'}
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default PaymentAcceptor;