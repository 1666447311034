import React, { Component } from 'react';
import SwitchView from './SwitchView';

// const wsUrl = (process.env.NODE_ENV == "production") ? process.env.WS_CABLE_URL : "ws://localhost:3000/cable";

class SwitchContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      // <ActionCableProvider url={wsUrl}>
        <SwitchView {...this.props} />
      // </ActionCableProvider>
    )
  }
}
export default SwitchContainer;