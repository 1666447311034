import React, { Component } from 'react';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0, 0.7)",
    boxShadow: "0 7px 74px 0 rgba(0, 0, 0, 0.3)",
    overflowY: "scroll",
    overflowX: "hidden"
  }
};
const marks = {
  5 : "1 week",
  10 : "1 month",
  15 : "6 months",
  20 : "1 year",
}
const mark_values = {
  5 : 0.96,
  10 : 4.16,
  15 : 25.00,
  20 : 50.00,
}

const EMAIL_VALIDATION_REGEX = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

class ConfirmationModal extends Component {
  render() {
    return(
      <Modal
            isOpen={this.props.showConfirmationModal}
            onRequestClose={this.props.closeModal}
            className="main__confirmation--modal"
            contentLabel="Confirmation Modal"
            style={customStyles}
            appElement={document.getElementById('root')}>
          <button type="button" className="close mx-3 my-2 main__confirmation--modal-close" aria-label="Close" onClick={this.props.closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="py-4 px-4">
            {
              this.props.confirmationError ? (
                <div className="alert alert-danger">
                  <span>{this.props.confirmationError}</span>
                </div>
              ) : null
            }
            <h3 className="font-weight-bold">Confirm message:</h3>
            <div>
              <div className="form-group">
                <label>Recipients:</label>
                <div className="d-flex justify-content-start flex-wrap">
                  { 
                    this.props.recipients.filter(el=>EMAIL_VALIDATION_REGEX.test(el.name)).map((el, index)=>{
                      return(<div className="main__form--recip-item-label" key={index}>{el.name}</div>)
                    })
                  }
                </div>
              </div>
              <div className="form-group">
                <label>Reminder Email:</label>
                <div className="d-flex justify-content-start flex-wrap">
                  {
                    ((this.props.reminderEmail.length > 0) && EMAIL_VALIDATION_REGEX.test(this.props.reminderEmail)) ? 
                    (<div className="main__form--recip-item-label">{this.props.reminderEmail}</div>) : 
                    (<div className="badge badge-warning">You have no valid reminder email set. We <strong>strongly</strong> recommend adding one.</div>)
                  }
                </div>
              </div>

              <div className="form-group">
                <label>Duration:</label>
                <div className="d-flex justify-content-start flex-wrap">
                  <div className="main__form--recip-item-label">
                    { marks[this.props.duration] } - ${ mark_values[this.props.duration] }
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Encrypted Message:</label>
                <div className="conf__modal--encrypted-text bg-light">
                  { this.props.encryptedMessage }
                </div>
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-lg btn-block" onClick={this.props.submitForm}>Confirm & Create</button>
              </div>
            </div>

          </div>
        </Modal>
    )
  }
}
export default ConfirmationModal;