import React, { Component } from 'react';
import Modal from 'react-modal';

class ErrorAlert extends Component {
  constructor(props) {
    super(props);
  }

  closeModal = () => {
    this.props.errorAlertClose();
  }

  render() {
    let errorMessages = this.props.message.map((el,index) => (<li key={index} className="mb-2">{el}</li>));
    return(
      <Modal
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel="Notification"
        appElement={document.getElementById('root')}
        className="main__error--modal alert alert-danger alert-dismissible fade show"
      >
        <div>
          <button type="button" className="close" onClick={this.closeModal} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <ul className="my-3">
            { errorMessages }
          </ul>
        </div>
      </Modal>)
  }
}
export default ErrorAlert;