import React, { Component } from 'react';
import Slider from 'rc-slider';
import axios from 'axios';
import PaymentAcceptor from './PaymentAcceptor';

const durationOptions = [
  {value: "0.96", text: "1 week"}, 
  {value: "4.16", text: "1 month"}, 
  {value: "25.00", text: "6 months"},
  {value: "50.00", text: "1 year"}
]

class PaymentCreator extends Component {

  constructor(props) {
    super(props);
    this.state = { amount: "4.16", payment: null }
  }

  onSubmit = (event) => {
    event.preventDefault();
    
    let csrfToken = document.querySelectorAll('meta[name=csrf-token]')[0].getAttribute("content");
    axios.post("/payments", { 
      message_id: this.props.messageId, amount: this.state.amount}, 
      {headers: {"Content-type": "application/json", 'X-CSRF-Token' : csrfToken }})
    .then(response => {
      console.log(response);
      this.setState({
        payment: response.data.payment
      })
    }).catch(error => {
      this.setState({
        confirmationError: "There was an error processing your request. Please try again later."
      })
    });
  }

  onSelectChange = (event) => {
    this.setState({
      amount: event.target.value
    })
  }

  render () {
    return (
      <div className="card my-3">
        <div className="card-header">Extend Switch</div>
        <div className="card-body container-fluid">
          {
            this.state.payment ? (<PaymentAcceptor payment={this.state.payment} />) : 
              (<form className="row align-items-end" onSubmit={this.onSubmit}>
                <div className="col-12 col-sm-8">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <label className="input-group-text" htmlFor="inputGroupSelect01">Duration:</label>
                    </div>
                    <select onChange={this.onSelectChange} value={this.state.amount} className="custom-select">
                      {
                        durationOptions.map((el,index)=>{
                          return(<option key={index} value={el.value}>{el.text}</option>)
                        })
                      }
                    </select>
                  </div>
                  <hr/>
                  <div className="my-2 text-right">
                    <span className="font-weight-bold mr-3">USD Price:</span>
                    <span>${parseFloat(this.state.amount).toFixed(2)}</span>
                  </div>
                </div>
                <div className="col-12 col-sm-4 text-right">
                  <button className="btn btn-primary btn-block my-2">Generate Invoice</button>
                </div>
              </form>
              )
          }
        </div>
      </div>
    );
  }
}

export default PaymentCreator;