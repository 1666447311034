import React, { Component } from 'react';

class TransactionHistory extends Component {

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <div className="card my-3">
        <div className="card-header">Payment History</div>
        <ul className="list-group list-group-flush">
          {
            this.props.payments.map(el => {
              return(
                <li className="list-group-item" key={el.guid}>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <span className="font-weight-bold">Txn ID: {el.guid}</span>
                    <span className="text-muted">{el.payment_received_at}</span>
                  </div>
                  <hr/>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <span className="font-weight-bold">Amount Received:</span>
                    <span className="text-muted">{el.satoshi_received/100000000} BTC</span>
                  </div>
                  <div className="d-flex justify-content-between flex-column flex-sm-row">
                    <span className="font-weight-bold">Switch Extended By:</span>
                    <span className="text-muted">{el.switch_extended_in_seconds_by} seconds</span>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    );
  }
}

export default TransactionHistory;