import React, { Component } from 'react';

import ErrorAlert from './ErrorAlert';
import ConfirmationModal from './components/ConfirmationModal';

import ReactTags from 'react-tag-autocomplete';
import axios from 'axios';
import sjcl from 'sjcl';
import ReactTooltip from 'react-tooltip';
import Slider from 'rc-slider';

const EMAIL_VALIDATION_REGEX = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
const marks = {
  5 : "1 week",
  10 : "1 month",
  15 : "6 months",
  20 : "1 year",
}
const mark_values = {
  5 : 0.96,
  10 : 4.16,
  15 : 25.00,
  20 : 50.00,
}
class MessageManager extends Component {

  constructor(props) {
    super(props);
    this.state = {
      encryptedMessage: "",
      passphrase: "", 
      confirmPassphrase: "", 
      message: "", 
      validationMessage: "", 
      showPassword: false,
      recipients: [], 
      reminderEmail: "", 
      showConfirmationModal: false, 
      errorMessages: [],
      confirmationError: "",
      duration: 10
    };
  }

  getEncryptedMessage = () => {
    let encryptedMessage = sjcl.encrypt(this.state.passphrase, this.state.message, {ks: 256});
    return encryptedMessage;
  }

  fieldChange = (event) =>{
    const value = event.target.value;
    const name = event.target.dataset.name;
    this.setState({
      [name]: value
    });
  }

  handleAddition = (tag) => {
    const recipients = [].concat(this.state.recipients, tag)
    this.setState({ recipients })
  }

  handleDelete = (i) => {
    const recipients = this.state.recipients.slice(0)
    recipients.splice(i, 1)
    this.setState({ recipients })
  }

  togglePassword = () =>{
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  onSliderChange = (value) => {
    this.setState({
      duration: value
    })
  }

  submitForm = () => {
    let csrfToken = document.querySelectorAll('meta[name=csrf-token]')[0].getAttribute("content");
    axios.post("/messages", { 
      cipher_text: this.state.encryptedMessage, recipients: this.state.recipients, 
      reminder_email: this.state.reminderEmail, duration: this.state.duration 
    }, {headers: {"Content-type": "application/json", 'X-CSRF-Token' : csrfToken }})
    .then(response => {
      window.location = ("/switches/"+response.data.id);
    }).catch(error => {
      this.setState({
        confirmationError: "There was an error processing your request. Please try again later."
      })
    });
  }

  closeModal = () => {
    this.setState({ showConfirmationModal: false });
  }

  confirmSubmit = () => {
    let errors = [];

    if (this.state.recipients.filter(el=>EMAIL_VALIDATION_REGEX.test(el.name)).length === 0) {
      errors.push("You must have atleast 1 recipient to create a switch.");
    }
    if (this.state.message.length === 0) {
      errors.push("You must enter a message in order to create a switch.");
    }

    if (this.state.passphrase.length === 0) {
      errors.push("You have not inputted any passphrase. We highly recommend you do this before continuing.");
    }

    if (errors.length>0){
    
      this.setState({ errorMessages: errors });
    
    } else {
      this.setState({ 
        showConfirmationModal: true,
        encryptedMessage: this.getEncryptedMessage()
      });
    }
  }

  closeAlert = () => {
    this.setState({errorMessages: [], validationMessage: ""});
  }

  onRecipBlur = () => {
    let currentInput = this.recipientsReactTags.input.input.value;
    if (EMAIL_VALIDATION_REGEX.test(currentInput)) {
      let currentEmails = this.state.recipients;
      currentEmails.push({ name: currentInput});

      this.setState({ recipients: currentEmails }, function(){
        this.recipientsReactTags.input.input.value = "";
        this.recipientsReactTags.input.input.dispatchEvent(new Event('change', { bubbles: true }));
      });
    }
  }

  render () {
    function customTag ({...props}){
      let validEmailClass = EMAIL_VALIDATION_REGEX.test(props.tag.name) ? "main__recip--email-true" : "main__recip--email-false";
      return (<button type='button' className={props.classNames.selectedTag + " " + validEmailClass} title='Click to remove tag' onClick={props.onDelete}>
              <span className={props.classNames.selectedTagName}>{props.tag.name}</span>
            </button>); 
    }

    return (
      <div className="main__form--container">
        <ReactTooltip html={true}
                      globalEventOff='click'
                      class="main__form--tooltip-container"/>
        {
          (this.state.errorMessages.length > 0) ? 
          <ErrorAlert message={this.state.errorMessages} errorAlertClose={this.closeAlert}/> : null
        }

        {
          this.state.showConfirmationModal ? 
            <ConfirmationModal 
              showConfirmationModal={this.state.showConfirmationModal}
              confirmationError={this.state.confirmationError}
              recipients={this.state.recipients}
              reminderEmail={this.state.reminderEmail}
              encryptedMessage={this.state.encryptedMessage}
              duration={this.state.duration}
              submitForm={this.submitForm}
              closeModal={this.closeModal}
            /> : null
        }
        <h3 className="mb-5">Create A Switch</h3>
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="form-group mb-5">
              <label className="font-weight-bold d-flex justify-content-between mb-0">
                <span>Duration:</span>
                <span className="main__form--price">$ {mark_values[this.state.duration].toFixed(2)}</span>
              </label>
              <p className="text-muted main__form--small-copy">Unless re-newed, your switch will expire at the set duration.</p>
              <div className="main__form--slider-container mt-2">
                <Slider step={null} min={5} max={20} marks={marks} defaultValue={this.state.duration} onChange={this.onSliderChange}/>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label className="font-weight-bold mb-0">
                  Recipients:
                </label>
                <span className="main__form--q-circle"
                      data-tip={"Enter up to 5 recipients. Give them the password and instruct them to store it securely. "+
                          "They will need it to decrypt your message. We cannot reset your password if it is lost. "+
                          "<a href='/faq' target='_blank' class='main__form--tooltip-link'>Read our FAQ.</a>"}
                      data-event='click focus'
                      data-html={true}> ? </span>
              </div>
              <p className="text-muted main__form--small-copy mt-1">These emails will receive your encrypted message when you stop re-newing and the switch expires.</p>
              <ReactTags  tags={this.state.recipients} 
                          handleAddition={this.handleAddition}
                          handleDelete={this.handleDelete}
                          delimiterChars={[',', ' ']}
                          allowNew={true}
                          tagComponent={customTag}
                          placeholder="john@doe.com"
                          handleBlur={this.onRecipBlur}
                          autofocus={false}
                          classNames={{
                            root: 'main__form--recipients-input d-flex flex-wrap',
                            selected: 'react-tags__selected d-inline-flex flex-wrap',
                            search: 'react-tags__search d-inline-flex align-items-center'
                          }}
                          ref={(c) => { this.recipientsReactTags = c }} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label className="font-weight-bold mb-0">
                  <span>Reminder Email:</span>
                </label>
                <span className="main__form--q-circle"
                      data-tip={"We will use this email to remind you when your switch is about to expire. You may leave it blank if you wish to use our service anonymously. "+
                          "<a href='/faq' target='_blank' class='main__form--tooltip-link'>Read our FAQ.</a>"}
                      data-event='click focus'
                      data-html={true}> ? </span>
              </div>
              <p className="text-muted main__form--small-copy">Reminders to re-new your switch will be sent to this email.</p>
              <input type="text" value={this.state.reminderEmail}
                placeholder="myemail@example.com"
                className="form-control main__form--input"
                data-name="reminderEmail" 
                onChange={this.fieldChange}/>
              
            </div>
          </div>
          <div className="col-12">
            <div className="main__form--encryption-reminder mb-2">
              Your message is automatically encrypted in your browser with your provided password before being sent to our servers. Use a strong password.
            </div>
          </div>

          <div className="col-md-12 mt-3">
            { this.state.validationMessage.length > 0 ? <ErrorAlert message={this.state.validationMessage} errorAlertClose={this.closeAlert}/> : null }
            <div className="form-group">
              <label className="font-weight-bold">Passphrase:</label>
              <div className="input-group">
                <input type={this.state.showPassword ? "text" : "password"} 
                        value={this.state.passphrase} 
                        placeholder="Enter passphrase" 
                        className="form-control main__form--input"
                        onChange={this.fieldChange} 
                        data-name="passphrase"
                        id="passphrase"/>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="passphrase">
                    <a className="main__form--password-toggle" onClick={this.togglePassword}>
                      {
                        this.state.showPassword ? 
                          (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="main__form--icon">
                            <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"/>
                          </svg>)
                        : (<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' className="main__form--icon">
                          <path d='M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z'/>
                        </svg>)
                      }
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group mb-0">
              <label className="font-weight-bold">Your Message:</label>
              <span className="main__form--q-circle"
                    data-tip={"Your message is encrypted in your browser before being sent to our servers but we recommend you encrypt it yourself first if it is sensitive. DO NOT put sensitive information below. Operate under the expectation that all messages written below can be read by a malicious actor even though we have taken precautions to make that less likely."}
                    data-event='click focus'> ? </span>
              <textarea value={this.state.message} 
                        placeholder="Enter you message here..." 
                        rows="8" 
                        className="form-control" 
                        onChange={this.fieldChange}
                        data-name="message" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group pt-4">
              <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={this.confirmSubmit}>Encrypt Message</button>
            </div>
          </div>
        </div>
      </div>);
  }
}  
export default MessageManager;
