import React, { Component, useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown-now';
import axios from 'axios';
// import { ActionCableConsumer } from 'react-actioncable-provider';

import PaymentAcceptor from './components/PaymentAcceptor';
import PaymentCreator from './components/PaymentCreator';
import TransactionHistory from './components/TransactionHistory';
import MessageDecryptor from './components/MessageDecryptor';

const renderer = ({ total, days, hours, minutes, seconds, milliseconds, completed }) => {
  return (<div className="d-flex justify-content-around">
    <div className="text-center"><div>{days}</div><small className="text-uppercase font-weight-bold">Days</small></div>
    <div className="text-center"><div>{hours}</div><small className="text-uppercase font-weight-bold">Hours</small></div>
    <div className="text-center"><div>{minutes}</div><small className="text-uppercase font-weight-bold">Minutes</small></div>
    <div className="text-center"><div>{seconds}</div><small className="text-uppercase font-weight-bold">Seconds</small></div>
  </div>);
}

const messageStateBadge = (status) => {
  return (
    <div className={"my-2 d-flex align-items-center account__status--badge " + ("account__status--badge-" + status) }>
      {
        status == "waiting" ? (
          <div className="spinner-border spinner-border-sm mr-2 text-white-50" role="status">
            <span className="sr-only">Waiting...</span>
          </div>
        ) : null
      }
      <div>Status: <strong className="text-capitalize">{status}</strong></div>
    </div>
  )
}

const switchCountdown = ({status, switchDate, expirationDate}) => {
  if ((status == "active") && switchDate) {
    return(
      <div className="card">
        <h6 className="card-header">Switch Termination In:</h6>
        <div className="card-body">
          <Countdown date={new Date(switchDate)} renderer={renderer}/>
        </div>
      </div>
    )
  } else if (status=="closed") {
    return(
      <div className="card">
        <h6 className="card-header">Switch Terminated On: {switchDate}</h6>
        <div className="card-body">
          <h6 className="card-subtitle mb-2 text-muted">This page will expire in:</h6>
          <Countdown date={expirationDate} renderer={renderer}/>
        </div> 
      </div>  
    )
  } else {
    return(null);
  }
}

class SwitchView extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      copied: false,
      guid: props.guid,
      status: props.status,
      switchDate: props.switchDate,
      expirationDate: props.expirationDate,
      encryptedMessage: props.encryptedMessage,
      payments: props.payments,
    }
  }
  componentDidMount (){
    this.fetchStatus();
  }

  fetchStatus = () => {
    axios.get(`/messages/${this.state.guid}/status`)
    .then(response => {
      this.setState({...response.data.message});
      setTimeout(this.fetchStatus, 5000);
    }).catch(error => {
      console.log(error);
      setTimeout(this.fetchStatus, 5000);
    });
  }

  onCopy = () => {
    this.setState({
      copied: true
    })
    setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  }

  // handleReceived = (message) => {
  //   this.setState(message);
  // }

  render () {
    let numberOfPayments = this.state.payments.length;
    let paymentsSection;
    if ((this.state.status == "waiting") && (numberOfPayments > 0 && this.state.payments[numberOfPayments-1].status == "unconfirmed")) {
      paymentsSection = <PaymentAcceptor payment={this.state.payments[numberOfPayments-1]}/>
    } else if (this.state.status == "active") {
      paymentsSection = <PaymentCreator messageId={this.state.guid} />
    }

    return (
      <div>
        <div className="card mb-4">
          <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row card-body">
            <h6 className="text-center mb-0"><strong>Account #:</strong> { this.state.guid }</h6>
            { messageStateBadge(this.state.status) }
          </div>
          <div className="card-footer text-muted">
          This is your Account ID.  You can use this to access your switch and reach this page again. This is especially important if you did not set a reminder email.
          </div>
        </div>
        
        { switchCountdown({status: this.state.status, switchDate: this.state.switchDate, expirationDate: this.state.expirationDate}) }

        { paymentsSection }

        {
          (this.state.status == "active" && this.state.payments.filter(el => (el.status=="confirmed")).length > 0) ? 
          (<TransactionHistory payments={this.state.payments.filter(el => (el.status=="confirmed"))} />) : null
        }
        {
          this.state.status == "closed" ? (<MessageDecryptor encryptedMessage={this.state.encryptedMessage}/>) : null
        }
      </div>
    );
  }
}

export default SwitchView;